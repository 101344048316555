import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListNews() {
    const [newsList, setNewsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchNewsList();
    }, []);

    const fetchNewsList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_newspaper.php');
            const newsData = response.data;
    
            // Handle the case where no data is found or unexpected data is returned
            if (newsData.response === 'E') {
                setNewsList([]); // Set empty array if the response indicates no data
            } else if (Array.isArray(newsData) && newsData.length > 0) {
                setNewsList(newsData); // Set the data if it's a valid non-empty array
            } else if (Array.isArray(newsData) && newsData.length === 0) {
                setNewsList([]); // Set empty array if the response is an empty array
            } else {
                setNewsList([]); // Handle unexpected data format by setting an empty list
                setError('Unexpected data format received.');
            }
        } catch (error) {
            console.error("An error occurred while fetching the News list:", error.message);
            setNewsList([]); // Set empty array on error
            setError("An error occurred while fetching the News list: " + error.message);
        } finally {
            setLoading(false);
        }
    };
    
    

    const deleteNews = async (NewsId) => {
        try {
            // Send DELETE request and capture the response
            const response = await axios.delete(`https://samvedanafoundation.com/exe_files/delete_news.php?NewsId=${NewsId}`);
            
            // Assuming response data is in the format { msg: "Banner Deleted", response: "D" }
            const { data } = response;
    
            // Check the server's response and update state if successful
            if (data.response === "D") {
                // Update the newsList state after deletion
                setNewsList(newsList.filter(news => news.NewsId !== NewsId));
                alert(data.msg); // Show the message from the server
            } else {
                alert("Failed to delete news");
            }
        } catch (error) {
            console.error("Error deleting News:", error.message);
            alert("An error occurred while deleting news");
        }
    };

    if (loading) {
        return <div className="text-center mt-8">Loading...</div>;
    }

    if (error) {
        return <div className="text-center mt-8">Error: {error}</div>;
    }
    return (
        <div>
            <div className="text-gray-900 ">
                <div className="p-4 flex">
                    <h1 className="text-3xl">List News</h1>
                </div>
                <div className="px-3 py-4 flex justify-center">
                    <table className="w-full text-md bg-white shadow-md rounded mb-4">
                        <thead>
                            <tr>
                                <th className="text-left p-3 px-5">Head</th>
                                <th className="text-left p-3 px-5">Extra</th>
                                <th className="text-left p-3 px-5">Description</th>
                                <th className="text-left p-3 px-5">Date</th>
                                <th className="text-left p-3 px-5">Place</th>
                                <th className="text-left p-3 px-5">Newspaper</th>
                                <th className="text-left p-3 px-5">Image</th>
                                <th className="text-left p-3 px-5">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {newsList.length > 0 ? (
                                newsList.map((newsItem, index) => (
                                    <tr key={index} className={index % 2 === 0 ? 'border-b hover:bg-orange-100 bg-gray-100' : 'border-b hover:bg-orange-100'}>
                                        <td className="p-3 px-5">{newsItem.Head}</td>
                                        <td className="p-3 px-5">{newsItem.ShortDescription}</td>
                                        <td className="p-3 px-5">{newsItem.Description}</td>
                                        <td className="p-3 px-5">{newsItem.Date}</td>
                                        <td className="p-3 px-5">{newsItem.Place}</td>
                                        <td className="p-3 px-5">{newsItem.NewsPaper}</td>
                                        <td className="p-3 px-5">
                                            <img 
                                                src={`https://samvedanafoundation.com/exe_files/images/activity_images/${newsItem.PhotoPath}`} 
                                                alt={newsItem.Head} 
                                                style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} 
                                            />
                                        </td>
                                        <td className="p-3 px-5 flex justify-end">
                                            <button type="button" className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline">Edit</button>
                                            <button 
                                                type="button" 
                                                onClick={() => deleteNews(newsItem.NewsId)} 
                                                className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="p-3 px-5 text-center">No news data available</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    );
}

export default ListNews;
