import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './Component/About/About';
import Products from './Component/Product/ProductNavbar/MainProduct/Products';
import AdminLogin from './Component/Login/AdminLogin';
import AddManasvardhan from './Component/Aminpannel/Manasvardhan/AddManasvardhan';
import AddGaushala from './Component/Aminpannel/Gaushala/AddGaushala';
import ListProduct from './Component/Aminpannel/Product/ListProduct';
import ListCustomer from './Component/Aminpannel/Customer/ListCustomer';
import AddCustomer from './Component/Aminpannel/Customer/AddCustomer';
import AddProduct from './Component/Aminpannel/Product/AddProduct';
import ListManasvardhan from './Component/Aminpannel/Manasvardhan/ListManasvardhan';
import ListGaushala from './Component/Aminpannel/Gaushala/ListGaushala';
import AddSamvedana from './Component/Aminpannel/Samvedana/AddSamvedana';
import HomeManasvardhan from './Component/Aminpannel/Manasvardhan/HomeManasvardhan';
import HomepageGaushala from './Component/Aminpannel/Gaushala/HomepageGaushala';
import { Sidebar } from 'flowbite-react';

import Award from './Component/Aminpannel/Awards/Award';
import HomeDonation from './Component/Donation/HomeDonation';
import ListVideo from './Component/Aminpannel/Samvedana/YouTube/AddVideoSam';
import AddSocial from './Component/Aminpannel/News/News/AddNews';
import ListSocial from './Component/Aminpannel/News/Social/ListSocial';
import Social from './Component/Aminpannel/News/Social/Social';
import AddNews from './Component/Aminpannel/News/News/AddNews';
import ListNews from './Component/Aminpannel/News/News/ListNews';
import NewsEvent from './Component/Aminpannel/News/NewsEvent';
import GallarySocial from './Component/Aminpannel/Awards/Social/GallarySocial';
import Home from './Component/Home/Home';
import AddVideoSam from './Component/Aminpannel/Samvedana/YouTube/AddVideoSam';
import YouTubePage from './Component/Aminpannel/Samvedana/YouTube/Display/YouTubePage';
import GheeHomeCard from './Component/Product/ProductHome/GheeHomeCard';
import AddHomePage from './Component/Aminpannel/HomePage/AddHomePage';
import ListHomePage from './Component/Aminpannel/HomePage/ListHomePage';
import ProductForm from './Component/Product/ProductHome/ProductForm';
import DiyaCard from './Component/Product/ProductHome/DiyaCard';
import DhoopCard from './Component/Product/ProductHome/DhoopCard';
import PanchgavyCard from './Component/Product/ProductHome/PanchgavyCard';
import Dashboard from './Component/Sidebar/Dashboard';
import AddProfesionalAwards from './Component/Aminpannel/Awards/Professional/AddProfesionalAwards';
import ListProfessionalAwards from './Component/Aminpannel/Awards/Professional/ListProfesionalAwards';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<HomeDonation />} />
          <Route path='/award' element={<Award />} /> 
          <Route path='/product' element={<Products />} />
          <Route path='/login' element={<AdminLogin />} />
          <Route path='/addSamvedana' element={<AddSamvedana />} />
          <Route path='/addGaushala' element={<AddGaushala />} />
          <Route path='/addManasvardhan' element={<AddManasvardhan />} />
          <Route path='/listSamvedana' element={<ListManasvardhan />} />
          <Route path='/listGaushala' element={<ListGaushala />} />
          <Route path='/listManasvardhan' element={<ListManasvardhan />} />
          <Route path='/addProduct' element={<AddProduct />} />
          <Route path='/listProduct' element={<ListProduct />} />
          <Route path='/addCustomer' element={<AddCustomer />} />
          <Route path='/listCustomer' element={<ListCustomer />} /> 
          <Route path='/addSocial' element={<AddSocial />} />
          <Route path='/listSocial' element={<ListSocial />} /> 
          <Route path='/social' element={<Social />} /> 
          <Route path='/activitygaushala' element={<HomepageGaushala />} /> 
          <Route path='/activitymanasvardhan' element={<HomeManasvardhan />} /> 
          <Route path='/sidebar' element={<Sidebar />} />
          <Route path='/addvideo' element={<AddVideoSam />} />
          <Route path='/listvideo' element={<ListVideo />} />
          <Route path="/videos" element={<YouTubePage />} />
          <Route path="/news" element={<NewsEvent />} />
          <Route path="/addnews" element={<AddNews />} /> 
          <Route path="/listnews" element={<ListNews />} />
          <Route path="/galarysocial" element={<GallarySocial />} />
          <Route path="/addGhee" element={<GheeHomeCard />} />
          <Route path="/addHomePage" element={<AddHomePage />} />
          <Route path="/listHomePage" element={<ListHomePage />} />
          <Route path="/productform" element={<ProductForm />} />
          <Route path="/adddiya" element={<DiyaCard />} />
          <Route path="/adddhoop" element={<DhoopCard />} />
          <Route path="/addsamrani" element={<PanchgavyCard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/AddProfessionalAwards" element={<AddProfesionalAwards />} />
          <Route path="/ListProfessionalAwards" element={<ListProfessionalAwards />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
