import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ListSocial() {
    const [gaushalaList, setGaushalaList] = useState([]);
    const [editItem, setEditItem] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchGaushalaList();
    }, []);

    const fetchGaushalaList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_social_news.php');
            setGaushalaList(response.data || []);
        } catch (error) {
            console.error("An error occurred while fetching the News list:", error.message);
            setGaushalaList([]);
        }
    };

    const deleteGaushala = async (index) => {
        try {
            const samvedanaItem = gaushalaList[index];
            if (!samvedanaItem || !samvedanaItem.NewsId) throw new Error("Item or ActivityId not found");

            const response = await axios.post('https://samvedanafoundation.com/exe_files/delete_social_news.php',
                JSON.stringify({ Id: samvedanaItem.NewsId }), {
                    headers: { 'Content-Type': 'application/json' }
                }
            );

            if (response.data.response === "D") {
                setGaushalaList(gaushalaList.filter((_, i) => i !== index));
                alert("Deleted successfully");
            } else {
                alert("Error: " + (response.data.msg || "Unknown error"));
            }
        } catch (error) {
            console.error("Error deleting:", error.message);
            alert("Error deleting. Please try again.");
        }
    };

    const handleEdit = (index) => {
        const itemToEdit = gaushalaList[index];
        setEditItem(itemToEdit);
        navigate('/addSocial', { state: { itemToEdit } }); // Pass item to AddSocial via route state
    };

    return (
        <div>
            <h1 className="text-3xl">List Gaushala</h1>
            <table className="w-full text-md bg-white shadow-md rounded mb-4">
                <thead>
                    <tr>
                        <th className="p-3">Head</th>
                        <th className="p-3">Extra</th>
                        <th className="p-3">Description</th>
                        <th className="p-3">Date</th>
                        <th className="p-3">Place</th>
                        <th className="p-3">Newspaper</th>
                        <th className="p-3">Image</th>
                        <th className="p-3">Edit/Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {gaushalaList.map((item, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                            <td>{item.Head}</td>
                            <td>{item.ShortDescription}</td>
                            <td>{item.Description}</td>
                            <td>{item.Date}</td>
                            <td>{item.Place}</td>
                            <td>{item.NewsPaper}</td>
                            <td>
                                <img src={`https://samvedanafoundation.com/exe_files/images/activity_images/${item.PhotoPath}`} alt="Gaushala" style={{ maxWidth: '100px' }} />
                            </td>
                            <td>
                                <button className="mr-3 bg-blue-500 text-white py-1 px-2 rounded" onClick={() => handleEdit(index)}>Edit</button>
                                <button className="bg-red-500 text-white py-1 px-2 rounded" onClick={() => deleteGaushala(index)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ListSocial;
