import React from 'react';
import Contactcomponent from '../Contact/Contactcomponent';
import HomeSamvedhana from '../Aminpannel/Samvedana/HomeSamvedhana';

import HomeAbout from './HomeAbout/HomeAbout';
import ProductHome from '../Product/ProductNavbar/Addcart/ProductHome';
import Navbar from '../Navbar/Navbar';
import Contactform from '../Aminpannel/Samvedana/Contactform';

import YouTubePage from '../Aminpannel/Manasvardhan/YouTube/Display/YouTubePage';
import ImageCollage from '../Work/ImageCollage';


function Home() {
    return (
        <div>
            <div><Navbar/></div>
            <div><HomeAbout/></div>
          
            <div><ProductHome /></div>
            <div><YouTubePage /></div>

            <div><HomeSamvedhana /></div>
            <div><Contactform/></div>
            {/* <ImageCollage/> */}
            <div><Contactcomponent /></div>
        </div>
    );
}

export default Home;
