import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ListCustomer() {
    const [customerList, setCustomerList] = useState([]);

    useEffect(() => {
        fetchCustomerList();
    }, []);

    const fetchCustomerList = async () => {
        try {
            const response = await axios.get('https://samvedanafoundation.com/exe_files/list_customer.php');
            setCustomerList(response.data);
        } catch (error) {
            console.error("An error occurred while fetching the customer list:", error.message);
            // Optionally, display an error message to the user
        }
    };

    const deleteCustomer = async (index) => {
        try {
            const customerItem = customerList[index];
            if (!customerItem || !customerItem.id) {
                throw new Error("Item or ID not found");
            }

            const response = await axios.post('https://samvedanafoundation.com/exe_files/delete_customer.php', 
                JSON.stringify({ Id: customerItem.id }), 
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.response === "D") {
                setCustomerList(customerList.filter((_, i) => i !== index));
                alert("Deleted successfully");
            } else {
                alert("Error: " + (response.data.msg || "Unknown error"));
            }
        } catch (error) {
            console.error("Error deleting:", error.message);
            alert("Error deleting. Please try again.");
        }
    };

    return (
        <div className="text-gray-900">
            <div className="p-4 flex">
                <h1 className="text-3xl">Customer List</h1>
            </div>
            <div className="px-3 py-4 flex justify-center">
                <table className="w-full text-md bg-white shadow-md rounded mb-4">
                    <thead>
                        <tr>
                            <th className="text-left p-3 px-5">ID</th>
                            <th className="text-left p-3 px-5">Name</th>
                            <th className="text-left p-3 px-5">Email</th>
                            <th className="text-left p-3 px-5">Mobile</th>
                            <th className="text-left p-3 px-5">Address</th>
                            <th className="text-left p-3 px-5">City</th>
                            <th className="text-left p-3 px-5">State</th>
                            <th className="text-left p-3 px-5">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerList.map((customer, index) => (
                            <tr key={customer.id} className={index % 2 === 0 ? 'border-b hover:bg-orange-100 bg-gray-100' : 'border-b hover:bg-orange-100'}>
                                <td className="p-3 px-5">{customer.id}</td>
                                <td className="p-3 px-5">{customer.name}</td>
                                <td className="p-3 px-5">{customer.email}</td>
                                <td className="p-3 px-5">{customer.mobile}</td>
                                <td className="p-3 px-5">{customer.address}</td>
                                <td className="p-3 px-5">{customer.city}</td>
                                <td className="p-3 px-5">{customer.state}</td>
                                <td className="p-3 px-5 flex justify-end">
                                    <button 
                                        type="button" 
                                        onClick={() => alert("edit")} // Add onClick event for edit button
                                        className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => deleteCustomer(index)}
                                        className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListCustomer;
