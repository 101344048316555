import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Newsletest() {
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await axios.get('https://samvedanafoundation.com/exe_files/list_newspaper.php');
      
      if (response.data && response.data.length > 0) {
        // Sort activities by date in descending order (newest first)
        const sortedActivities = response.data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        setActivities(sortedActivities);
      } else {
        setError('No activities found');
      }
      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto py-8">
      {activities.map((activity, index) => (
        <div key={index} className="max-w-8xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mb-8">
          <div className="px-6 py-4">
            <h1 className="text-3xl font-bold text-gray-800 text-center">{activity.Head}</h1>
            <div className="flex items-center justify-between mt-4">
              <p className="text-sm font-bold text-gray-600">{activity.Newspaper}</p>
              <span className="text-sm font-bold text-gray-600">
                {activity.Date}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{activity.Place}
              </span>
            </div>
            <img 
              src={`https://samvedanafoundation.com/exe_files/images/activity_images/${activity.PhotoPath}`} 
              alt={activity.ShortDescription || 'Activity'} 
              className="w-full h-auto object-cover object-center" 
            />
            <div className="mt-4">
              <h4 className="text-xl font-semibold text-gray-700">{activity.ShortDescription}</h4>
              <p className="mt-2 text-gray-600">{activity.Description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Newsletest;
